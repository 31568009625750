<template>
  <div>
    <b-form-group
      label="Bitiş Tarihi"
      label-for="edate"
    >
      <validation-provider
        #default="{ errors }"
        name="Bitiş Tarihi"
        rules="required"
      >
        <b-input-group>
          <cleave
            id="edate"
            v-model="formattedDate"
            class="form-control"
            :raw="false"
            :options="options.date"
            placeholder="Tarih Seçiniz"
            @blur="setDate"
          />
          <b-input-group-append>
            <b-form-datepicker
              v-model="dataItem.edate"
              button-only
              dropright
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
              locale="tr"
              start-weekday="1"
              button-variant="outline-primary"
              size="sm"
              @context="onContext"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  name: 'ContractDate',
  components: {
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    Cleave,
  },
  data() {
    return {
      required,
      formattedDate: null,
      options: {
        date: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    setDate() {
      if (this.formattedDate.length === 10 && moment(this.formattedDate, 'DD.MM.YYYY', true)) {
        if (this.dataItem.edate !== moment(this.formattedDate, 'DD.MM.YYYY', true).format('YYYY-MM-DD')) {
          this.dataItem.edate = moment(this.formattedDate, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
        }
      }
    },
    onContext(ctx) {
      this.formattedDate = ctx.selectedFormatted
      this.dataItem.edate = ctx.selectedYMD
    },
  },
}
</script>
